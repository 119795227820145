@import "../../assets/styles/mixins";

.section {
  padding-top: 20px;
}

.image {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto 30px auto;
  pointer-events: none;
}

.content {
  text-align: center;
}

.bgNet {
  display: none;
}

.title {
  margin-bottom: 10px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
}

.info {
  font-size: 14px;
  line-height: 19px;
}

@include for-desktop-sm {
  .section {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .bgNet {
    width: 360px;
    display: block;
    position: absolute;
    top: 30%;
    z-index: 0;
    left: calc(100% - 360px);
    pointer-events: none;
  }

  .image {
    margin: 0 60px 0 0;
    max-width: 300px;
  }

  .content {
    position: relative;
    z-index: 1;
    max-width: 550px;
    text-align: left;
  }

  .title {
    margin-bottom: 20px;
    font-size: 30px;
  }

  .info {
    font-size: 16px;
    line-height: 22px;
  }
}

@include for-desktop-big {
  .bgNet {
    left: calc(100% - 340px);
  }
}