@import "@/assets/styles/helper";

.container {
  text-align: center;
}

.locked {
  display: block;
  margin-bottom: 10px;
  color: transparentize($color-black, 0.5);
  font-size: 13px;
  line-height: 18px;
}

.balance {
  display: block;
  margin-top: auto;
  margin-bottom: 15px;
  font-size: 18px;
}

@include for-desktop-sm {
  .locked {
    font-size: 16px;
    line-height: 22px;
  }
}
