@import "@/assets/styles/helper";

.poweredBy {
  display: block;
  margin-bottom: 2px;
  text-align: center;
  font-size: 9px;
  text-transform: uppercase;
  font-family: $default-font;
  color: transparentize($color-black, 0.6);
}
