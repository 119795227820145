@import "@/assets/styles/helper";

.section {
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  overflow: hidden;
}

.image {
  display: block;
  width: 100%;
  max-width: 500px;
  transform: scale(1.3);
  margin: 17% auto;
  pointer-events: none;
}

.title,
.description {
  color: $color-white-1;
  text-align: center;
}

.title {
  margin-bottom: 20px;
  font-size: 38px;
  line-height: 90%;
  text-transform: uppercase;

  &>span {
    display: block;
  }
}

.description {
  margin-bottom: 20px;
  font-size: 16px;
}

.buyBtn {
  display: block;
  width: 100%;
  max-width: 280px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: none;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: $default-border-radius;
  overflow: hidden;
  transition: $default-transition-ms transform;

  .buyBtnText {
    position: relative;
    z-index: 1;
    background: $primary-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-white-1;
    z-index: 0;
  }

  &:hover {
    transform: translateY(-3px);
  }
}

@include for-tablet-big {
  .image {
    transform: scaleX(1);
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@include for-desktop-sm {
  .section {
    overflow: visible;
    position: relative;
    padding-bottom: 0;
  }

  .container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 200px;
    align-items: center;
    height: 100%;
  }

  .image {
    display: block;
    position: absolute;
    right: 2%;
    bottom: -5%;
    width: 70%;
    max-width: 800px;
    margin-bottom: 0;
  }

  .content {
    position: relative;
    order: 1;
    flex-shrink: 0;
    flex-basis: 45%;
  }

  .title,
  .description {
    text-align: left;
  }

  .title {
    margin-bottom: 30px;
    font-size: 50px;
  }

  .description {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .buyBtn {
    margin: 0;
    max-width: 162px;
  }
}

@include for-desktop {
  .title {
    font-size: 70px;
  }

  .image {
    min-width: 880px;
    bottom: -0%;
  }

  .container {
    padding-top: 100px;
    padding-bottom: 300px;
  }
}

@include for-desktop-big {
  .image {
    right: 0;
  }

  .container {
    position: relative;
  }
}
