@import "@/assets/styles/helper";

.select {
  display: block;

  &_disabled {
    & .control {
      background-color: darken($color-white-1, 12%) !important;
      cursor: not-allowed;
    }
  }

  .control {
    border: none;
    box-shadow: none;
    background-color: $color-white-2;
    border-radius: $default-border-radius;

    &:hover {
      border-color: $color-white-3;
      cursor: pointer;
    }
  }

  .valueContainer {
    padding-left: 5px;
    padding-right: 0;
  }

  .menu {
    background-color: $color-white-2;
    border-radius: $default-border-radius;
    overflow: hidden;
  }

  .singleValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: $color-black;
    text-transform: uppercase;
    margin-right: 0;
  }

  .option {
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    text-transform: uppercase;
    cursor: pointer;

    background: $color-white-2;

    &:hover {
      background: darken($color-white-2, 1);
    }

    &Inner {
      padding: 10px 0;
      border-bottom: 1px solid $color-white-3;
      text-align: center;

      @include gradientText($color-black);

      &_selected {
        @include gradientText();
      }
    }

    &:last-child {
      & .optionInner {
        border-bottom: unset;
      }
    }

    .valueContainer {
      border-bottom: 2px solid $color-black;
    }
  }

  .indicatorsContainer {
    padding-right: 0;
  }

  .dropdownIndicator {
    font-size: 18px;
    color: $color-red;
    transition: transform $default-transition-ms;
  }
}

@include for-desktop-sm {
  .select {
    .valueContainer {
      padding-left: 12px;
    }

    .indicatorsContainer {
      padding-right: 12px;
    }
  }
}
