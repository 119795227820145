@import "@/assets/styles/helper";

.list {
  padding: 0 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.tab {
  flex-basis: calc(50% - 4px);
  max-width: 190px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &.active {
    .btn {
      height: 70px;
      background: $color-white-1;
      border-left-color: $color-orange;
      border-right-color: $color-orange;
      border-top-color: $color-orange;
    }

    .title {
      color: $color-black;
    }
  }
}

.btn {
  width: 100%;
  height: 50px;
  padding: 0 5px;
  border-top-right-radius: $default-border-radius;
  border-top-left-radius: $default-border-radius;
  background: rgba(0, 0, 0, 0.03);

  border: {
    left: 1px solid rgba(0, 0, 0, 0.1);
    top: 1px solid rgba(0, 0, 0, 0.1);
    right: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 1px solid transparent;
  }
}

.title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: transparentize($color-black, 0.4);
}

.subtitle {
  display: block;
  font-size: 12px;
  color: transparentize($color-black, 0.5);
}

.hidden {
  display: none;
}

@include for-tablet {
  .btn {
  }

  .title {
    font-size: 16px;
  }
}
