@import "@/assets/styles/helper";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-z-index;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(50, 67, 95, 0.6);

}

.modal {
  position: relative;
  padding: 30px 20px;
  background: #F8F8F8;
  border-radius: $default-border-radius;
}

.closeBtn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  background: $primary-gradient;
  transition: $default-transition-ms transform;
  border: none;
  border-radius: 50%;

  &:hover {
    transform: translateY(-1px);
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 10px;
    height: 2px;
    border-radius: 12px;
    background-color: $color-white-1;
  }

  &:before {
    transform: translate(50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(50%, -50%) rotate(-45deg);
  }
}

@include for-desktop-sm {
  .modal {
    padding-left: 30px;
    padding-right: 30px;
  }
}
