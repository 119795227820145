@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.modal {
  width: 95%;
}

.header {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.headerStageName {
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: $primary-gradient;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.status {
  display: block;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.description {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  &.hidden {
    display: none;
  }
}

.targetBtn {
  display: block;
  margin: 20px auto 0 auto;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
}

.icon {
  color: $color-white-1;
  font-size: 28px;
}

@include for-tablet {
  .modal {
    width: 600px;
  }
}
