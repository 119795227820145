@import "@/assets/styles/helper";

.section {
  position: relative;
}

.container {
  width: calc(100% - $margin-m);
  margin-left: $margin-m;
  position: relative;
}

.titleBlock {
  margin: 0 auto;
  margin-bottom: $margin-m;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin-m;

  text-align: center;
  font-weight: 400;
  @include fontSize('xxxl');
  text-transform: uppercase;

  @include for-desktop-sm {
    @include fontSize('xxxxl');
  }

  &Text {
    display: inline-block;
    margin-right: $margin-s;

    @include for-desktop-sm {
      margin-right: $margin-m;
    }
  }

  &Label {
    position: relative;
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    background: $primary-gradient;

    .titleLabelText {
      position: relative;
      z-index: 1;
      @include gradientText();
    }

    &:after {
      content: "";
      background: $color-white-1;
      display: block;
      position: absolute;
      z-index: 0;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }
  }
}

.description {
  margin-bottom: $margin-m;
  text-align: center;
  text-transform: uppercase;

  font-weight: 400;
  @include fontSize(m);
  color: $color-black;

  &> :first-child {
    @include gradientText();
  }
}

.listContainer {
  width: 100%;
  background-color: #F8F8F8;
  border-radius: $default-border-radius;
  overflow-x: auto;
}

.list {
  width: 100%;
  padding: 25px 10px;
  display: flex;
}

.item {
  padding: 0 10px;
  min-width: 180px;

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.percent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 auto 15px auto;
  border-radius: 50%;
  background: $primary-gradient;
  color: $color-white-1;
  font-weight: 700;
  @include fontSize(xl);
}

.name {
  display: block;
  font-weight: 400;
  @include fontSize(m);
  text-align: center;
}

.bouncyMan {
  display: none;
}

@include for-tablet-big {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
  }

  .list {
    justify-content: center;
  }

  .item {
    flex-grow: 1;
  }
}

@include for-desktop-sm {
  .bouncyMan {
    display: block;
    position: absolute;
    width: 220px;
    bottom: 50%;
    left: calc(100% - 220px);
    z-index: 0;
    pointer-events: none;
  }

  .item {
    position: relative;
    z-index: 1;
  }
}

@include for-desktop-big {
  .bouncyMan {
    left: calc(100% - 180px);
  }
}
