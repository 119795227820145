@import "@/assets/styles/helper";

.wrapper {
  display: none;
  position: fixed;
  z-index: $tooltip-z-index;
}

.content {
  padding: 10px 20px;
  max-width: 450px;

  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: $default-border-radius;
  background-color: $color-white-1;
  border: 1px solid $color-white-3;
}
