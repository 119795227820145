@import "@/assets/styles/helper";

.options {
  min-width: 240px;
}

.option {
  &:not(:last-child) {
    border-bottom: 1px solid $color-white-3;
  }
}

.btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 0;
  text-transform: uppercase;
  font-size: 14px;
  border: none;
  color: $color-black; // BUG: without this Safari will paint this into blue color (somewhat...)

  &:hover {
    .icon {
      margin-right: 18px;
    }
  }
}

.icon {
  transition: $default-transition-ms margin-right;
  margin-right: 15px;
}
