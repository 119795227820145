@import "@/assets/styles/helper";

.select {
  display: block;

  .control {
    min-height: 56px;
    border: 1px solid $color-white-3;
    box-shadow: none;
    background-color: $color-white-2;
    border-radius: $default-border-radius;

    &:hover {
      border-color: $color-white-4;
      cursor: pointer;
    }
  }

  .valueContainer {
    padding-left: 5px;
    padding-right: 0;
  }

  .menu {
    background-color: $color-white-2;
    border-radius: $default-border-radius;
    overflow: hidden;
  }

  .singleValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: $color-black;
    text-transform: uppercase;
    margin-right: 0;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    text-transform: uppercase;
    background-color: $color-white-2;
    cursor: pointer;

    &:hover {
      background-color: darken($color-white-2, 1);
    }
  }

  .indicatorsContainer {
    padding-right: 0;
  }

  .dropdownIndicator {
    font-size: 18px;
    color: $color-red;
    transition: transform $default-transition-ms;
  }
}

.currencyIcon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  color: $color-white-1;
  border: 2px solid $color-white-1;
  background: $primary-gradient;
}

@include for-desktop-sm {
  .select {
    .valueContainer {
      padding-left: 12px;
    }
    .indicatorsContainer {
      padding-right: 12px;
    }
  }
}

