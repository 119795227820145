@import "@/assets/styles/helper";

.container {
  width: 100%;
  position: relative;
}

.title {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.list {
  display: flex;
  padding: 25px 10px;
  overflow-x: auto;
}

.stage {
  position: relative;
  min-width: 180px;
  max-width: 245px;
  flex-grow: 1;
  padding: 50px 30px 35px 30px;
  background: #F8F8F8;
  border-radius: $default-border-radius;
  text-align: center;

  flex-basis: calc(25% - 45px / 3);

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.active {
    background: $primary-gradient;
    box-shadow: 0px 4px 20px rgba(255, 66, 60, 0.42);

    .header, .amount, .currency, .weeks {
      color: $color-white-1;
    }

    .price {
      background: $color-white-1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .border {
      display: none;
    }

    .status {
      background: $primary-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.completed {
    .status {
      background: $primary-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 15px;
  height: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
  text-align: center;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  overflow: hidden;

  .status {
    position: relative;
    background: $color-black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-white-1;
  }
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
}

.checkmark {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  flex-shrink: 0;
}

.hidden {
  display: none;
}

.currency {
  display: block;
  margin-bottom: 25px;
  font-size: 18px;
}

.price {
  display: block;
  margin-bottom: 25px;
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-weight: 700;
}

.weeks {
  display: block;
  font-size: 16px;
}

.border {
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $primary-gradient;
  border-radius: $default-border-radius;
}

.bgNet {
  display: none;
}

@include for-desktop-sm {
  .title {
    margin-bottom: 40px;
  }

  .list {
    justify-content: center;
  }

  .checkmark {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .stage {
    flex-basis: calc(25% - 120px / 3);

    &:not(:last-child) {
      margin-right: 40px;
    }

    &:not(.active) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .bgNet {
    display: block;
    width: 250px;
    position: absolute;
    bottom: 10%;
    right: 15%;
    z-index: 0;
    pointer-events: none;
  }
}
