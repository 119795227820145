@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.title {
  margin-bottom: 20px;
}

.container {
  position: relative;
}

.card {
  padding: 30px 50px;
  position: relative;
  background-color: #F8F8F8;
  border-radius: $default-border-radius;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .btnText {
    background: $color-black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.name {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
}

.address {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}

.border, .bgNet, .bowlImg {
  display: none;
}

@include for-desktop-sm {
  .title {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

  .card {
    flex-basis: calc(50% - 20px);
    max-width: 430px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:not(:last-child) {
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  .border {
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $primary-gradient;
    border-radius: $default-border-radius;
  }

  .bgNet {
    display: block;
    width: 240px;
    position: absolute;
    top: 5%;
    left: 0;
    pointer-events: none;
  }

  .bowlImg {
    display: block;
    width: 90px;
    position: absolute;
    top: -35%;
    right: -10%;
    z-index: -1;
  }
}

@include for-desktop {
  .bgNet {
    left: 10%;
  }
}

