@import "@/assets/styles/helper";

.toast {
  padding: 20px;
  width: 100%;
  max-width: 550px;
  text-align: center;
  margin: 0 auto 25px auto;

  &Btn {
    padding-left: 8px;
    padding-right: 8px;
  }

  &Content {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 19px;
  }
}

@include for-tablet {
  .toast {
    &Btn {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .toast {
    position: relative;
    padding-left: 60px;
    padding-right: 60px;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    background-color: $color-white-1;
    border: 1px solid $color-white-3;

    &:after {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: -13px;
      left: 30%;
      transform: rotate(45deg);
      background: $color-white-1;

      border: {
        right: 1px solid $color-white-3;
        bottom: 1px solid $color-white-3;
      }
    }
  }
}
