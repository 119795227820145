@import '@/assets/styles/helper';

.loader {
  width: 33px;
  height: 33px;
  display: inline-block;
  padding: 0;
  border-radius: 100%;
  border: 6px solid $color-white-1;
  border-right-color: transparentize($color-white-1, 0.8);
  border-bottom-color: transparentize($color-white-1, 0.8);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
