@import "@/assets/styles/helper";

.menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  text-align: center;
  flex-grow: 1;
}

.externalLinks {
  margin-top: 10px;
  margin-bottom: 15px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.links {
  display: block;
  margin-top: 40px;
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.description {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  font-family: $default-font;
}

.downloadLinks {
  margin-top: $margin-m;
  margin-bottom: $margin-m;
}

.downloadLink {
  width: 125px;
  margin-left: auto;
  margin-right: auto;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.languageSelect {
  margin: 0 auto $margin-m;
  width: 200px;
  border-radius: $default-border-radius;
  border: 1px solid $color-orange;
}

.footer {
  margin-top: auto;
  width: 100%;
  // padding-bottom for ios devices
  padding: $margin-m 0 100px 0;
  background-color: $color-white-2;
  border-top: 1px solid $color-white-3;
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLink {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.developer {
  margin-top: $margin-m;
}

.copyright {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
}
