@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../fonts/NotoSans-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../fonts/NotoSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

