@import "@/assets/styles/helper";

$marginRight-desktop-sm: 20px;
$marginRight-desktop: 30px;

.header {
  width: 100%;
  padding: 10px 0;

  &.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 0;
    background-color: $color-white-1;
    overflow: auto;
    z-index: $header-z-index;
  }
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.logo {
  width: 52px;
  height: auto;

  &.extended {
    width: 83px;
  }
}

.showConnectWallet {
  display: none;
}

.burger {
  position: absolute;
  top: 18px;
  right: 0;
}

.externalLinks {
  display: none;
}

.mobileMenu {
  &.hidden {
    display: none;
  }
}

.walletConnectedModal {
  position: relative;

  &CloseBtn {
    z-index: 1;
    top: 10px;
    right: 10px;
  }
}

.languageSelect {
  display: none;
}

.connectBtn {
  display: none;
}

@include for-desktop-sm {
  .header {
    padding: 50px 0;
  }

  .showConnectWallet {
    display: flex;
  }

  .burger {
    display: none;
  }

  .container {
    align-items: center;
    justify-content: flex-end;
  }

  .logo {
    width: 114px;
    position: absolute;
    left: 0;
    top: -20px;
  }

  .externalLinks {
    display: flex;
    align-items: center;
    margin-right: $marginRight-desktop-sm;
  }

  .externalLink {
    color: $color-white-1;
  }

  .externalLinksItem {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .languageSelect {
    display: unset;
    margin-right: $marginRight-desktop-sm;
  }

  .connectBtn {
    display: block;
    padding: 10px 20px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: $color-white-1;
    border-radius: $default-border-radius;
    border: none;
  }
}

@include for-desktop {
  .languageSelect {
    margin-right: $marginRight-desktop;
  }

  .externalLinks {
    margin-right: $marginRight-desktop;
  }
}
