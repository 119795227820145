@import "@/assets/styles/helper";

.list {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.item {
  min-height: 115px;
  padding: 15px 10px;
  background-color: #F8F8F8;
  border-radius: $default-border-radius;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.daysLeft {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ratesTitle {
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
}

.daysLeftCount {
  display: inline-block;
  margin-right: 5px;
  font-size: 30px;
}

.progressBar {
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  display: block;
  height: 10px;
  background-color: #CFCFCF;
  border-radius: $default-border-radius;
  overflow: hidden;
}

.progressBarLine {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $primary-gradient;
}

.progressDescription {
  text-align: center;
  color: transparentize($color-black, 0.5);
  font-size: 14px;
}

.priceValue {
  display: block;
  margin-bottom: 13px;
  text-align: center;
  font-size: 30px;
}

.priceDescription {
  display: block;
  font-size: 14px;
  text-align: center;
}

.ratesList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.rate {
  &Item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 50%;
    min-width: 120px;
    padding-right: 15px;
    margin-bottom: 5px;
  }

  &Icon {
    display: flex;
    margin-right: 7px;
    width: 26px;
    height: 26px;
    align-items: center;
    justify-content: center;
    background: $primary-gradient;
    color: $color-white-1;
    font-size: 14px;
    border-radius: 50%;
  }
}

@include for-tablet {
  .list {
    flex-direction: row;
    justify-content: center;
  }

  .item {
    flex-basis: calc(33.33% - 60px / 3);
    max-width: 260px;
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
}
