@import "../../assets/styles/variables";

.btn {
  width: 38px;
  height: 26px;
  padding: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: none;

  &.active > span {
    transform: rotate(45deg);
  }

  &.active > span:before {
    top: 0;
    transform: rotate(0);
  }

  &.active > span:after {
    top: 0;
    transform: rotate(90deg);
  }

  & > span,
  & > span:before,
  & > span:after {
    position: absolute;
    display: block;
    height: 2px;
    border-radius: 2px;
    background: $color-white-1;
    transition-duration: $default-transition-ms;
  }

  & > span {
    left: 5px;
    width: calc(100% - 10px);
  }

  & > span:before, & > span:after {
    width: 100%;
  }

  & > span:before {
    content: "";
    top: -8px;
  }

  & > span:after {
    content: "";
    top: 8px;
  }

  &.active > span,
  &.active > span:before,
  &.active > span:after {
    background: $color-black;
  }
}
