@mixin fontSize($size: 'default') {
  @if $size== 's' {
    font-size: 14px;
    line-height: 16px;
  } @else if $size== 'm' {
    font-size: 16px;
    line-height: 22px;
  } @else if $size== 'l' {
    font-size: 18px;
    line-height: 21px;
  } @else if $size== 'xl' {
    font-size: 20px;
  } @else if $size== 'xxl' {
    font-size: 22px;
  } @else if $size== 'xxxl' {
    font-size: 24px;
    line-height: 33px;
  } @else if $size== 'xxxxl' {
    font-size: 30px;
    line-height: 41px;
  }

  @content;
}
