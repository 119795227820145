@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.section {
  position: relative;
}

.title {
  margin-bottom: 10px;
}

.content {
  position: relative;
  z-index: 1;
}

.container {
  width: 100%;
  max-width: 900px;
  padding: 20px 10px;
  position: relative;
  background-color: #F8F8F8;
}

.balancesBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progressBar {
  margin-bottom: 20px;
}

.info {
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: transparentize($color-black, 0.5);
}

.womanImg {
  display: none;
}

.leftBg {
  display: none;
}

.tubeImg {
  display: none;
}

.plumeImg {
  display: none;
}

@include for-desktop-sm {
  .container {
    padding: 25px 50px;
    border-radius: $default-border-radius;
  }

  .label {
    position: absolute;
    top: -50px;
    right: -90px;
    margin-bottom: 0;
  }

  .content {
    width: 65%;
    margin-left: auto;
  }

  .womanImg {
    display: block;
    width: 35%;
    position: absolute;
    bottom: 0;
    left: -50px;
    pointer-events: none;
  }

  .title {
    margin-bottom: 45px;
  }

  .balancesBlock {
    display: flex;
    flex-direction: row;
    justify-content: initial;
    align-items: initial;
  }

  .balance {
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-white-1;
    border-radius: $default-border-radius;
  }

  .info {
    font-size: 16px;
    line-height: 22px;
  }

  .leftBg {
    display: block;
    position: absolute;
    left: -13%;
    bottom: 14%;
    z-index: -1;
    pointer-events: none;
  }

  .tubeImg {
    display: block;
    position: absolute;
    left: 35%;
    bottom: -7%;
  }
}

@include for-desktop {
  .plumeImg {
    display: block;
    position: absolute;
    right: -12%;
    bottom: 5%;
    z-index: -1;
    transform: rotate(-10deg);
  }
}
