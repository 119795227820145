@import "@/assets/styles/helper";

.preview {
  background: $primary-gradient;

  @include for-desktop-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 700px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/preview-bg.svg");
  }
}

.features {
  margin-top: 40px;
}

.bonuses {
  margin-top: 40px;
}

.presalePlan {
  margin-top: 40px;
}

.addresses {
  margin-top: 40px;
}

.team {
  margin-top: 40px;
}

.footer {
  margin-top: 40px;
}

.sportsPeople {
  width: 100%;
  max-width: 210px;
  display: block;
  margin: 35px auto 0 auto;
  pointer-events: none;
}

.buyForm {
  margin-top: 40px;
}

@include for-tablet {
  .sportsPeople {
    max-width: 360px;
  }
}

@include for-desktop-sm {
  .sportsPeople {
    margin-top: 80px;
  }
  .bonuses {
    margin-top: 170px;
  }

  .presalePlan {
    margin-top: 150px;
  }

  .addresses {
    margin-top: 35px;
  }

  .team {
    margin-top: 120px;
  }

  .footer {
    margin-top: 140px;
  }

  .buyForm {
    margin-top: 150px;
  }
}
