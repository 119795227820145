@import "./assets/fonts/fontello/css/fontello.css";
@import "./assets/fonts/fontello/css/fontello-codes.css";
@import "./assets/styles/variables";
@import "./assets/styles/mixins";
@import "./assets/styles/fonts";
@import "./assets/styles/normalize";

// fix fontello width
[class^="icon-"]:before, [class*=" icon-"]:before {
  width: auto;
}

// <-- BEGIN: fix Google Translate Widget
.goog-te {
  &-gadget {
    display: none;
  }

  &-banner-frame {
    display: none;
  }
}

body {
  top: 0 !important;
}

#google_translate_element {
  display: none;
}
// END -->
