@import "../../assets/styles/variables";

$size: 35px;

.link {
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-gradient;
  border-radius: 50%;
  border: 3px solid $color-white-1;
  color: $color-white-1;
  font-size: 14px;
  transition: $default-transition-ms transform;

  &:hover {
    transform: translateY(-2px);
  }
}
