@import "@/assets/styles/helper";

.wrap {
  position: relative;
  padding-top: 25px;
}

.input {
  padding: 15px 45% 15px 25px;
  width: 100%;
  height: 92px;
  border-radius: $default-border-radius;
  border: 1px solid $color-white-3;
  font-size: 24px;
  font-weight: 700;
  transition: background $default-transition-ms, box-shadow $default-transition-ms;

  &:focus {
    outline: none;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
    background-color: $color-white-1;
  }
}

.label {
  position: absolute;
  bottom: calc(100% - 20px);
  left: 0;
  color: transparentize($color-black, 0.5);
  font-size: 14px;
  text-transform: uppercase;
}

.right {
  width: 40%;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(calc(-50% + 10px));
}

@include for-tablet {
  .wrap {
    padding-top: 0;
  }

  .input {
    padding-top: 40px;
  }

  .label {
    top: 17px;
    left: 25px;
  }

  .right {
    transform: translateY(-50%);
  }
}
