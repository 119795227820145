*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: 'NotoSans', Arial, Helvetica, sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  font-family: 'NotoSans', Arial, Helvetica, sans-serif;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}
