@import "@/assets/styles/helper";

.footer {
  padding: 30px 0 20px 0;
  border-top: 1px solid $color-white-3;
  background-color: $color-white-2;
  text-align: center;
}

.up {
  display: none;
}

.externalLinks {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.externalLink {
  text-transform: uppercase;
  font-size: 14px;
  font-family: $default-font;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.socialLinks {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.socialLink {
  &:not(:Last-child) {
    margin-right: 12px;
  }
}

.developer {
  margin-bottom: 35px;
}

.copyright {
  font-size: 12px;
  font-family: $default-font;
}

@include for-desktop-sm {
  .bottom {
    padding-top: 40px;
    border-top: 1px solid $color-white-3;
  }

  .up {
    display: block;
    position: relative;
    top: -75px;
    margin-bottom: -30px;
  }

  .logo {
    width: 115px;
    margin-bottom: 15px;
  }

  .live {
    display: block;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 400;
    background: $primary-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }

  .description {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .downloadAppLinks {
    display: flex;
    justify-content: center;
  }

  .downloadAppLink {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
