@import "@/assets/styles/helper";

.wallet {
  position: relative;
  padding: 10px 10px 20px 10px;
  min-width: 300px;
  background: $primary-gradient;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: $default-border-radius;
  overflow: hidden;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: $default-border-radius;
    left: 1px;
    top: 1px;
    background-color: #F8F8F8;
  }

  &Title {
    display: block;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &Content {
    position: relative;
    z-index: 1;
  }

  &Description {
    color: transparentize($color-black, 0.5);
    font-size: 14px;
    line-height: 19px;
  }
}

.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px 10px;
  background-color: $color-white-1;
  border-radius: $default-border-radius;

  .addressCopyText {
    background: $color-black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.addressCopyBtn {
  padding-left: 20px;
  padding-right: 20px;
}

.addressValue {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  line-height: 19px;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 0 auto;
  padding: 5px;
  background: none;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  color: $color-black;
}

.logoutIcon {
  margin-right: 12px;
}
