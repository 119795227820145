@import "@/assets/styles/helper";

.modal {
  width: 95%;
}

.icon {
  color: $color-white-1;
  font-size: 28px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: $primary-gradient;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}

.title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

@include for-tablet {
  .modal {
    width: 600px;
  }
}
