@import "@/assets/styles/helper";

.container {
  width: 100%;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  padding: 20px 10px;
  background-color: $color-white-1;
  border: 1px solid $color-orange;
  border-radius: $default-border-radius;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
}

.title {
  margin-bottom: 20px;
}

.titleGradient {
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tabs {
  position: relative;
  top: 1px;
}

.stats {
  margin-top: 40px;
}

@include for-tablet {
  .content {
    padding: 30px 60px 50px 60px;
  }
}

@include for-desktop-sm {
  .section {
    // height as with fiat form
    // min-height: 1140px;
    background-image: url("../../assets/images/buy-bg.png");
    background-position: calc(50% + 50px) 230px;
    background-repeat: no-repeat;
    background-size: 1645px;

    &_wallet-connected {
      background-position: calc(50% + 50px) 70px;
    }
  }
}
