@import "../../assets/styles/mixins";

.title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.simpleTitle {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

@include for-desktop-sm {
  .title {
    font-size: 44px;
  }
  .simpleTitle {
    font-size: 30px;
  }
}