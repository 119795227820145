@import "@/assets/styles/helper";

.container {
  display: flex;
  flex-direction: column;
}

.title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.description {
  display: block;
  font-size: 12px;
  color: transparentize($color-black, 0.5);
  text-transform: uppercase;
}

.progressBarWrap {
  position: relative;
}

.progressBar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  height: 62px;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid $color-white-3;
  background-color: $color-white-1;
  border-radius: $default-border-radius;
}

.progressBarLine {
  display: block;
  height: 50px;
  background: $primary-gradient;
  border-radius: $default-border-radius;
}

.progressBarCap {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 1;
}

.softCapWrap {
  position: relative;
  display: flex;
  align-items: center;
  max-width: max-content;
  margin-bottom: 5px;
}

.softCap,
.hardCap {
  display: flex;
  flex-direction: column;
}

.softCap {
  max-width: 80px;
}

.hardCapWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.hardCap {
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 100px;

  .description {
    text-align: right;
  }
}

.hardCapTriangle {
  margin-bottom: 2px;
  transform: rotate(180deg);
}

@include for-desktop-sm {
  .description {
    font-size: 14px;
    line-height: 20px;
  }

  .container {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .progressBarWrap {
    order: 1;
    flex-basis: 100%;

    .progressBarCap {
      margin-bottom: 25px;
      position: relative;
      top: 0;
      left: 0;
      transform: translateY(0);
    }
  }

  .progressBar {
    height: 42px;
  }

  .progressBarLine {
    height: 30px;
  }

  .softCapWrap {
    order: 2;
    flex-basis: 0%;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-top: 10px;

    .softCap {
      max-width: 90px;
    }

    .softCapTriangle {
      margin-bottom: 3px;
      order: 1;
      transform: rotate(180deg);
    }

    .title {
      order: 2;
    }

    .description {
      order: 3;
    }
  }

  .hardCapWrap {
    position: relative;
    left: 100%;
    transform: translateX(-200%); // due to SOFT CAP on the same row and HARDAP starts from right
    order: 3;
    flex-basis: 0%;
    justify-content: flex-end;
    margin-top: 10px;

    .hardCap {
      max-width: 115px;
    }
  }
}
