$default-transition-ms: 0.2s;
$default-border-radius: 12px;

// fonts
$default-font: 'Mulish', Arial, Helvetica, sans-serif;

// z-index
$tooltip-z-index: 10;
$header-z-index: 5;
$modal-z-index: 7;

// colors
$color-black: #000000;
$color-orange: #FFA439;
$color-red: #FF2E3C;
$color-white-1: #ffffff;
$color-white-2: #F8F8F8;
$color-white-3: #DCDCDC;
$color-white-4: darken($color-white-3, 10);

$primary-gradient: linear-gradient(90deg, $color-orange 0%, $color-red 100%);
$gradient-1: linear-gradient(90deg, $color-red 0%, $color-red 100%);

// margins
$margin-m: 20px;
$margin-s: 10px;

