@import "@/assets/styles/helper";

.btn {
  position: relative;
  font-size: 16px;
  padding: 10px 60px;
  text-align: center;
  text-transform: uppercase;
  border-radius: $default-border-radius;
  background: $primary-gradient;
  border: none;

  .children {
    position: relative;
    @include gradientText();
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: $color-white-1;
    border-radius: $default-border-radius;
  }

  &:hover {
    background: $gradient-1;

    .children {
      @include gradientText($gradient-1);
    }
  }

  &_disabled {
    background: #C3C3C3 !important;
    color: rgba(white, 0.3) !important;
  }
}

.targetBtn {
  position: relative;
  font-size: 25px;
  font-weight: 700;
  padding: 10px 60px;
  text-align: center;
  text-transform: uppercase;
  border-radius: $default-border-radius;
  background: $primary-gradient;
  color: $color-white-1;
  border: none;
  transition: $default-transition-ms background;

  &:hover {
    background: linear-gradient(90deg, #FFD098 0%, #FF0011 100%);
  }
}
