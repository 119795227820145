@import "@/assets/styles/helper";

.formGroup {
  &:first-child {
    z-index: 1;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  width: 100%;
  margin: 0 auto 20px auto;
  background-color: #F8F6FF;
  border-radius: $default-border-radius;
  font-size: 14px;
  font-weight: 400;
}

.description {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
}

.submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.talentumLabel {
  height: 55px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  background: #F8F8F8;
  border-radius: $default-border-radius;
}

.talentumIcon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  background: $primary-gradient;
  border: 2px solid $color-white-1;
  border-radius: 50%;
  color: $color-white-1;
}

@include for-tablet {
  .balance {
    flex-direction: row;
    justify-content: space-between;
  }

  .talentumLabel {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include for-desktop-sm {
  .balance {
    margin-bottom: 30px;
  }
}
