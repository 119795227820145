@import "./typography";

@mixin for-desktop-big {
  @media screen and (min-width: 1560px) {
    @content;
  }
}

@mixin for-desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin for-desktop-sm {
  @media screen and (min-width: 980px) {
    @content;
  }
}

@mixin for-tablet-big {
  @media screen and (min-width: 720px) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (min-width: 640px) {
    @content;
  }
}

@mixin for-tablet-sm {
  @media screen and (min-width: 480px) {
    @content;
  }
}

@mixin for-phone-big {
  @media screen and (min-width: 400px) {
    @content;
  }
}

//
@mixin gradientText($color: $primary-gradient) {
  background: $color;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
