@import "@/assets/styles/helper";

.form {
  width: 100%;
  height: 595px;
  border-radius: $default-border-radius;
  overflow: hidden;
}

:global(.F7Ems) {
  button {
    background: $primary-gradient;
  }
}

@include for-tablet {
  .form {
    border: 1px solid $color-white-3;
  }
}
