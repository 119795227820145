@import "@/assets/styles/helper";

$marginRight-desktop-sm: 20px;
$marginRight-desktop: 30px;
$social-links-inner-margin: 24px;

.externalLink {
  font-weight: 700;
  font-family: $default-font;
  text-transform: uppercase;
}

.socialLinks {
  display: none;
}

@include for-desktop-sm {
  .socialLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $marginRight-desktop-sm;
  }

  .socialLink {
    &:not(:last-child) {
      margin-right: $social-links-inner-margin * 0.5;
    }

    & a {
      background: unset;
      border: 2px solid $color-white-1;
    }
  }
}

@include for-desktop {
  .socialLinks {
    margin-right: $marginRight-desktop;
  }

  .socialLink {
    &:not(:last-child) {
      margin-right: $social-links-inner-margin;
    }
  }
}
