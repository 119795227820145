@charset "UTF-8";

.icon-instagram:before { content: '\e800'; } /* '' */
.icon-facebook:before { content: '\e801'; } /* '' */
.icon-reddit:before { content: '\e802'; } /* '' */
.icon-discord:before { content: '\e803'; } /* '' */
.icon-mail:before { content: '\e804'; } /* '' */
.icon-bnb:before { content: '\e806'; } /* '' */
.icon-telegram:before { content: '\e807'; } /* '' */
.icon-usdt:before { content: '\e808'; } /* '' */
.icon-busd:before { content: '\e809'; } /* '' */
.icon-talentum:before { content: '\e80a'; } /* '' */
.icon-rejected:before { content: '\e80b'; } /* '' */
.icon-weth:before { content: '\e80c'; } /* '' */
.icon-checkmark:before { content: '\e80d'; } /* '' */
.icon-tiktok:before { content: '\e80e'; } /* '' */
.icon-twitter:before { content: '\e810'; } /* '' */
.icon-chevron:before { content: '򐷿'; } /* '\90dff' */
