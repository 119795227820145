@import "@/assets/styles/helper";

.section {
  overflow: hidden;
}

.title {
  margin-bottom: 20px;
}

.container {
  position: relative;
  width: 100%;
}

.members {
  display: flex;
  overflow: auto;
  padding: 65px 10px 20px 10px;
}

.member {
  position: relative;
  min-width: 260px;
  flex-grow: 1;
  padding: 80px 10px 10px 10px;
  border-radius: $default-border-radius;
  text-align: center;
  border: 1px solid #AFAFAF;
  flex-basis: calc(33.33% - 20px / 3);
  background-color: $color-white-1;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.photo {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
}

.name {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.position {
  display: block;
  margin-bottom: 20px;
  color: transparentize($color-black, 0.5);
  font-size: 18px;
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  white-space: break-spaces;
}

.bgNet {
  display: none;
}

@include for-tablet {
  .container {
    padding-left: 10px;
  }
}

@include for-desktop-sm {
  .title {
    margin-bottom: 70px;
  }

  .members {
    padding-top: 100px;
  }

  .member {
    flex-basis: calc(33.33% - 140px / 3);
    padding: 130px 20px 65px 20px;

    &:not(:last-child) {
      margin-right: 70px;
    }
  }

  .photo {
    width: 195px;
    height: 195px;
    top: calc(-195px / 2);
  }

  .description {
    font-size: 16px;
  }

  .bgNet {
    display: block;
    width: 240px;
    position: absolute;
    bottom: 35%;
    right: -3%;
    pointer-events: none;
  }
}

@include for-desktop {
  .member {
    min-width: 320px;
  }

  .bgNet {
    right: -3%;
  }
}
